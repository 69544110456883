import React, { useEffect, useState } from 'react';
import { Text } from '@dls/web';
import { theme } from '@dls/web';
import { withTheme } from '@dls/web/build/core/ThemeProvider';
import styled from 'styled-components';
import media from '../../helpers/media';
import { isIFrame , getChannelLayoutConfig} from '../../helpers/utils';
import { useRecoilValue } from 'recoil';
import { flowConfigState } from '../..';
import { aemConfigState } from '../../index';

const FooterWrapper = styled.footer`
  padding-top: 64px;
  padding-bottom: 24px;
  ${media.sm`  
    padding-top: 120px;
    padding-bottom: 40px;
  `}
  margin-top:auto;
`;

const FooterLink = withTheme(styled.span<{ coreTheme: any }>`
  & + &:before {
    content: ' ';
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: -3px ${theme.space[4]}px;
    background: ${(p) => p.coreTheme.cl_ter_base};
  }

  a {
    letter-spacing: -0.3px;
  }
`);

const FooterText = styled(Text)`
  text-align: center;
  letter-spacing: -0.2px;
`;

FooterText.defaultProps = {
  type: 'body'
};

const footerLinkData = [
  {
    text: 'Contact Us',
    link: 'https://www.singtel.com/contact-us'
  },
  {
    text: 'Data protection',
    link: 'https://www.singtel.com/data-protection'
  },
  {
    text: 'Terms of use',
    link: 'https://www.singtel.com/standard-agreement'
  }
];

export default function Footer() {
  let [isIframe, setIsIFrame] = useState(false);
  let flowConfig = useRecoilValue(flowConfigState);
  let aemConfig = useRecoilValue(aemConfigState);
  let channelLayoutConfig = getChannelLayoutConfig(flowConfig?.flowId, aemConfig.layoutConfig);
  const {footer} = channelLayoutConfig;
 
  useEffect(() => {
    setIsIFrame(isIFrame());
  }, []);

  if (isIframe) return null;

  return (
    <FooterWrapper>
      <FooterText>
        &copy; Singtel (CRN: 199201624D) All Rights Reserved.
      </FooterText>
      <div style={{ textAlign: 'center' }}>
        {footer.map((item) => (
          <FooterLink key={item.text}>
            <Text type="link" href={item.link} target="_blank">
              {item.text}
            </Text>
          </FooterLink>
        ))}
      </div>
    </FooterWrapper>
  );
}
